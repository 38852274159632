var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-image" },
    [
      _c(
        "a-upload",
        {
          attrs: {
            name: "avatar",
            "list-type": _vm.listType,
            disabled: _vm.disabled,
            "show-upload-list": false,
            "before-upload": _vm.beforeUpload,
            "file-list": _vm.fileList,
            multiple: true,
            "custom-request": _vm.customRequest,
          },
          on: { change: _vm.handleChange },
        },
        [
          _c(
            "a-button",
            [
              _c("a-icon", { attrs: { type: "upload" } }),
              _vm._v(" " + _vm._s(_vm.btnText)),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "uploadDescription" }, [
        _vm._v(_vm._s(_vm.uploadDescription)),
      ]),
      _vm.showUploadList
        ? _c(
            "div",
            { staticClass: "fileList" },
            [
              _vm._l(_vm.fileList, function (item, flieIndex) {
                return [
                  _c(
                    "div",
                    { key: "fileItem" + flieIndex, staticClass: "fileItem" },
                    [
                      _c("div", { staticClass: "fileIcon" }, [
                        _c("img", {
                          attrs: { src: item.thumbUrl, alt: "", srcset: "" },
                        }),
                      ]),
                      _c("div", { staticClass: "fileName" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _c("div", { staticClass: "icon" }, [
                        _vm.isDelete
                          ? _c(
                              "svg",
                              {
                                staticClass: "iconpark-icon Circle-Button",
                                on: {
                                  click: function ($event) {
                                    return _vm.onDelete(item, flieIndex)
                                  },
                                },
                              },
                              [_c("use", { attrs: { href: "#close-small" } })]
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }